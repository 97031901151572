export const environment = {
  production: true,
  apiUrl: 'https://vid-sverhu.ru/wb/api',
  womanSheet: 'Женская одежда и обувь',
  manSheet: 'Мужская одежда и обувь',
  wbOtherSheet: 'Другие товары с wb',
  wbProductsBucket: 'wb-products',
  msgImgBucket: 'msg-img',
  authTgBot: 'wildberries_sheet_bot',
  isDemo: false,
};
